import * as Sentry from '@sentry/react'
import { authAtom } from 'atoms/Auth'
import { sessionAtom, sessionModeAtom, sessionReducer } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { workplaceAtom } from 'atoms/Session/Workplace'
import { BroadcastChannel } from 'broadcast-channel'
import { currentUserAtom } from 'components/layouts/PageLayout/AppBar/_components/CurrentUserName'
import AdminOnlyPage from 'components/pages/AdminOnlyPage'
import TimeClockPage from 'components/pages/TimeClockPage'
import addDays from 'date-fns/addDays'
import getUnixTime from 'date-fns/getUnixTime'
import { useGetCurrentUserQuery } from 'gql/hooks'
import useImmerReducer from 'helpers/useImmerReducer'
import { useAtomValue, useSetAtom } from 'jotai'
import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

function AppRoute() {
  const userId = Cookies.get('uid')
  const business = useAtomValue(businessAtom)
  const workplace = useAtomValue(workplaceAtom)
  const location = useLocation()
  const [result] = useGetCurrentUserQuery({
    pause: !userId,
    requestPolicy: 'network-only',
  })
  const setCurrentUser = useSetAtom(currentUserAtom)
  const sessionMode = useAtomValue(sessionModeAtom)
  const [session, dispatch] = useImmerReducer(sessionAtom, sessionReducer)

  const channel = useMemo(() => new BroadcastChannel('sessionWorkplaceId'), [])

  useEffect(() => {
    channel.addEventListener('message', e => {
      if (session.workplaceId !== e.workplaceId) {
        window.location.reload()
      }
    })
    // return () => channel.close()
  }, [])

  useEffect(() => {
    if (
      result.data &&
      result.data.currentUser &&
      result.data.defaultBusiness &&
      location.pathname !== '/signup'
    ) {
      if (!session.userId) {
        dispatch({
          type: 'login',
          userId: result.data.currentUser.id,
          businessId: result.data.defaultBusiness.id,
          employeeId: result.data.defaultBusinessEmployee?.id,
          workplaceId: result.data.defaultBusinessEmployee?.workplace?.id,
        })
      } else {
        dispatch({
          type: 'update',
          session: {
            ...session,
            expireAt: getUnixTime(addDays(new Date(), 59)),
          },
        })
      }
    }
  }, [dispatch, location.pathname, result, session, session.userId])

  useEffect(() => {
    if (result.data && result.data.currentUser) {
      setCurrentUser(result.data.currentUser)
      Sentry.setUser({
        id: result.data.currentUser.id,
        businessId: result.data.defaultBusiness?.id,
        employeeId: result.data.defaultBusinessEmployee?.id,
        workplaceId: result.data.defaultBusinessEmployee?.workplace?.id,
      })
    }
  }, [dispatch, result, session, setCurrentUser])

  if (sessionMode === 'timeclock' && business && workplace) {
    return <TimeClockPageWrapper />
  }

  return (
    <div>
      <Outlet />
    </div>
  )
}

const TimeClockPageWrapper = () => {
  const auth = useAtomValue(authAtom)

  if (!auth) return null
  return auth.isOwner ||
    auth.isAdmin ||
    auth.hasFullPrivilege ||
    auth.hasTimeClockPrivilege ||
    auth.hasAttendancePrivilege ? (
    <TimeClockPage />
  ) : (
    <AdminOnlyPage />
  )
}

export default AppRoute
