import { sessionAtom } from 'atoms/Session'
import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { useAtomValue } from 'jotai'
import React, { useContext, useMemo } from 'react'
import PhoenixSocket from './PhoenixSocket'

const context = React.createContext<PhoenixSocket | null>(null)

interface Props {
  children: React.ReactNode
}

export function ProvideSocket({ children }: Props) {
  const sessionExpired = useIsSessionExpired()
  const { businessId, employeeId, workplaceId } = useAtomValue(sessionAtom)
  const socket = useMemo(
    () =>
      !sessionExpired
        ? new PhoenixSocket({ businessId, employeeId, workplaceId })
        : null,
    [businessId, employeeId, sessionExpired, workplaceId]
  )
  return <context.Provider value={socket}>{children}</context.Provider>
}

export function useSocket() {
  return useContext(context)
}
