import axios from 'axios'
import { getEnv } from 'env'
import Cookies from 'js-cookie'

const logout = async () => {
  Cookies.remove('uid')
  const { status } = await axios.post(
    `${getEnv().authApiUrl}/logout`,
    {},
    {
      withCredentials: true,
    }
  )
  if (status === 200) {
    window.localStorage.removeItem('session')
    window.location.href = getEnv().websiteUrl
  }
}

export default logout
