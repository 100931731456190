import { workplaceAtom } from 'atoms/Session/Workplace'
import addSeconds from 'date-fns/addSeconds'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import format from 'date-fns/format'
import { getEnv } from 'env'
import parseDate from 'helpers/parseDate'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSocket } from 'websocket/provider'
import { pipe, subscribe } from 'wonka'
import Roster from './_components/Roster'
import { businessAtom } from 'atoms/Session/Business'
import useImmerReducer from 'helpers/useImmerReducer'
import { sessionAtom, sessionReducer } from 'atoms/Session'
import getUnixTime from 'date-fns/getUnixTime'
import addDays from 'date-fns/addDays'
import { LinearProgress } from '@mui/material'
import ExitButton from './_components/ExitButton'
import QRcode from 'qrcode.react'
import hash from 'helpers/hash'
import Cookies from 'js-cookie'

// FIXME: move keys to backend
const QRCODE_KEY = 'xMiIsInR5cCI6IkpXVCJ9'

function TimeClockPage() {
  const storedTimeOffset = parseInt(Cookies.get('timeOffset') || '0')
  const socket = useSocket()
  const navigate = useNavigate()
  const workplace = useAtomValue(workplaceAtom)
  const queryBusiness = useSetAtom(businessAtom)
  const [offset, setOffset] = useState(storedTimeOffset)
  const [timestamp, setTimestamp] = useState(
    addSeconds(new Date(), storedTimeOffset)
  )
  const [progress, setProgress] = useState(0)
  const [session, dispatch] = useImmerReducer(sessionAtom, sessionReducer)

  useEffect(() => {
    if (window.location.pathname !== '/') {
      navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    if (socket) {
      const subscription = pipe(
        socket.subscribeToWorkplaceChannel(),
        subscribe((msg: any) => {
          if (msg.payload.current_time) {
            const timeOffset = differenceInSeconds(
              parseDate(msg.payload.current_time),
              new Date()
            )
            Cookies.set('timeOffset', `${timeOffset}`, { expires: 365 })
            setOffset(timeOffset)
          }
        })
      )

      return () => subscription.unsubscribe()
    }
  }, [socket])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 1))
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [timestamp])

  useEffect(() => {
    if (progress === 0) {
      socket?.getCurrentTime()
      setTimestamp(addSeconds(new Date(), offset))
      queryBusiness()
      dispatch({
        type: 'update',
        session: {
          ...session,
          expireAt: getUnixTime(addDays(new Date(), 59)),
        },
      })
    }
  }, [
    dispatch,
    offset,
    progress,
    queryBusiness,
    session,
    socket,
    storedTimeOffset,
  ])

  const hashKey = useMemo(
    () => (workplace ? hash(`${QRCODE_KEY}:${workplace?.id}`) : null),
    [workplace]
  )

  if (!workplace) return null

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 64,
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: 8, right: 8 }}>
        <ExitButton />
      </div>

      <div
        style={{
          borderRadius: '18%',
          width: 32,
          height: 32,
          overflow: 'hidden',
          flexShrink: 0,
          position: 'absolute',
          top: 8,
          left: 8,
        }}
      >
        <img
          src={process.env.PUBLIC_URL + '/appIcon.png'}
          alt=""
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div style={{ color: 'grey', position: 'absolute', bottom: 8, left: 8 }}>
        {`v${getEnv().version}`}
      </div>
      <QRcode size={168} value={`${hashKey}:${timestamp.getTime()}`} />

      <span style={{ fontSize: '7vmin' }}>{workplace.name}</span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <span style={{ fontSize: '13vmin' }}>
          {format(addSeconds(new Date(), offset), 'H:mm:ss')}
        </span>
        <LinearProgress
          style={{ width: '100%', marginTop: '-2vmin' }}
          variant="determinate"
          value={progress}
        />
      </div>

      <Roster key={`${progress === 100}`} />
    </div>
  )
}

export default TimeClockPage
