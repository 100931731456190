import * as Sentry from '@sentry/react'
import { authAtom } from 'atoms/Auth'
import { businessModeAtom, sessionAtom } from 'atoms/Session'
import { businessAtom } from 'atoms/Session/Business'
import { workplacesAtom } from 'atoms/Session/Business/Workplaces'
import NewAppVersionPrompt from 'components/prompts/NewAppVersionPrompt'
import RenewalPrompt from 'components/prompts/RenewalPrompt'
import { getEnv } from 'env'
import useIsSessionExpired from 'hooks/Auth/useIsSessionExpired'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSocket } from 'websocket/provider'

function AuthOnly({ children }: { children: JSX.Element }) {
  const sessionExpired = useIsSessionExpired()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const socket = useSocket()
  const auth = useAtomValue(authAtom)
  const session = useAtomValue(sessionAtom)
  const business = useAtomValue(businessAtom)
  const businessMode = useAtomValue(businessModeAtom)
  const workplaces = useAtomValue(workplacesAtom)
  const queryBusiness = useSetAtom(businessAtom)
  const queryWorkplaces = useSetAtom(workplacesAtom)

  useEffect(() => queryBusiness(), [queryBusiness])
  useEffect(() => queryWorkplaces(), [queryWorkplaces])

  useEffect(() => {
    if (sessionExpired) {
      window.localStorage.removeItem('session')
      window.location.href = getEnv().websiteUrl
      // navigate('/login', { replace: true })
    }
  }, [navigate, session, sessionExpired])

  useEffect(() => {
    if (socket?.unAuthenticated) {
      Sentry.setContext('session', { session })
      Sentry.captureMessage('AuthOnly socket unAuthenticated')
      window.localStorage.removeItem('session')
      navigate('/login', { replace: true })
    }
  }, [navigate, session, socket?.unAuthenticated])

  useEffect(() => {
    if (
      business &&
      workplaces &&
      auth &&
      ((!auth.hasAttendancePrivilege && pathname === '/attendance') ||
        (!auth.hasPayrollPrivilege && pathname === '/payroll') ||
        (!auth.hasSchedulingPrivilege && pathname === '/schedule') ||
        (!auth.hasSchedulingPrivilege && pathname === '/positions') ||
        (!auth.hasSchedulingPrivilege && pathname === '/masterSchedule') ||
        (!auth.hasStaffManagementPrivilege && pathname === '/employees') ||
        (!auth.hasTimeClockPrivilege && pathname === '/timeClock') ||
        (!auth.hasFullPrivilege && pathname === '/settings') ||
        (!businessMode && pathname === '/reports') ||
        (businessMode &&
          (pathname === '/attendance' ||
            pathname === '/payroll' ||
            pathname === '/schedule' ||
            pathname === '/positions' ||
            pathname === '/masterSchedule')))
    ) {
      navigate('/', { replace: true })
    }
  }, [pathname, navigate, business, auth, workplaces, businessMode])

  return (
    // <NotSupportedTimezonePrompt>
    <RenewalPrompt>
      <NewAppVersionPrompt>{children}</NewAppVersionPrompt>
    </RenewalPrompt>
    // </NotSupportedTimezonePrompt>
  )
}

export default AuthOnly
